import React, { useState } from 'react';
import './navbar.scss';
import { HiMenu, HiX } from 'react-icons/hi';
import { motion } from 'framer-motion';


const Navbar = () => {

    const [toggle, setToggle] = useState(false);
    

    return (
        <nav className='app_navbar'>
            <div className="logo">
                <h1><a href="/">Jawad Taj</a></h1>
            </div>
            <ul className='links'>
                {
                    ['home', 'about', 'work', 'skills','contact'].map((item) => (
                        <li className='app__flex' key={`link-${item}`}>
                            <div />
                            <a href={`#${item}`}>{item}</a>
                        </li>
                    ))
                } 
            </ul>
            <div className="download-cv">
                <button className="download-btn"><a href="https://cdn.sanity.io/files/xrhomt89/production/94ecaa6776900eef7b03137f531212177534629e.pdf" target="_blank" rel="noreferrer">Download CV</a></button>
            </div>
            <div className="nav-menu">
                <HiMenu onClick= {() => setToggle(true)} />
                {
                    toggle && (
                        <motion.div
                            whileInView = {{x : [200, 0]}}
                            transition = {{duration: 0.3, ease: 'easeInOut'}}
                        >
                            <HiX onClick= {() => setToggle(false)} />
                            <ul>
                                {
                                    ['home', 'about', 'work', 'skills','contact'].map((item) => (
                                        <li key={item}>
                                            <a href={`#${item}`} onClick= {() => setToggle(false)}>{item}</a>
                                        </li>
                                    ) )
                                }
                            </ul> 
                        </motion.div>
                    )
                }
            </div>
        </nav>
    )

}

export default Navbar;