import React, { useState, useEffect } from 'react';
import './work.scss';
import { AiFillEye, AiFillGithub } from 'react-icons/ai';
import { motion } from 'framer-motion';
import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';


const Work = () => {

    const [activeFilter, setActiveFilter] = useState("All");
    const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1});
    const [works, setWorks] = useState([]);
    const [filterWork, setFilterWork] = useState([]);

    useEffect(() => {
        const query = '*[_type == "works"]';
        client.fetch(query).then((data) => {
            setWorks(data);
            setFilterWork(data);
        });
    }, []);

    const handleWorkFilter = (item) => {

        setActiveFilter(item);
        setAnimateCard({ y: 100, opacity: 0});

        setTimeout(() => {
            setAnimateCard({ y: 0, opacity: 1});  
            if(item === 'All')
            {
                setFilterWork(works);
            }
            else
            {
                setFilterWork(works.filter((work) => work.tags.includes(item)));
            }
        }, 500);

    }


    return (
        <div className='work'>
            <h2 className="head-text">My Creative <span>Portfolio</span> Section</h2>
            <div className="work-filter">
                {
                    ['UX/UI', 'restaurant', 'landing page', 'ecommerce', 'All'].map((item, index) => (
                        <div
                            key={index}
                            onClick={() => handleWorkFilter(item)}
                            className={`work-filter-item app__flex p-text ${ activeFilter === item ? 'item-active' : ''}`}
                        >
                            {item}
                        </div>
                    ))
                }
            </div>
            <motion.div
                animate={animateCard}
                transition={{duration: 0.5, delayChildren: 0.5}}
                className="work-portfolio"
            >       
                {filterWork.map((item, index) => (
                    <div className="work-item app__flex" key={index}>
                        <div className="work-img app__flex">
                            <img src={urlFor(item.imgUrl)} alt={item.name} />
                            <motion.div
                                whileHover={{ opacity: [0, 1] }}
                                transition={{ duration: 0.25, ease: 'easeInOut', staggerChildren: 0.5 }}
                                className="work-hover app__flex"
                            >
                                <a href={item.projectLink} target="_blank" rel="noreferrer" >
                                    <motion.div
                                        whileInView={{ scale: [0, 1] }}
                                        whileHover={{ scale: [1, 0.9] }}
                                        transition={{ duration: 0.25, ease: 'easeInOut', staggerChildren: 0.5 }}
                                        className="app__flex icon"
                                    >
                                        <AiFillEye />
                                    </motion.div>
                                </a>
                                <a href={item.codeLink} target="_blank" rel="noreferrer" >
                                    <motion.div
                                        whileInView={{ scale: [0, 1] }}
                                        whileHover={{ scale: [1, 0.9] }}
                                        transition={{ duration: 0.25, ease: 'easeInOut', staggerChildren: 0.5 }}
                                        className="app__flex icon"
                                    >
                                        <AiFillGithub />
                                    </motion.div>
                                </a>
                            </motion.div>
                        </div>
                        <div className="work-tag app__flex">
                            <p className="p-text">{item.tags[0]}</p>
                        </div>
                        <div className="work-content app__flex">
                            <h4 className="bold-text">{item.title}</h4>
                            <p className="p-text" style={{ marginTop: 10 }}>{item.description}</p> 
                        </div>  
                    </div>
                ))}
            </motion.div>   
        </div>
    )

}

export default AppWrap(MotionWrap(Work, 'work'), 'work');