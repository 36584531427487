import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './about.scss';
import { urlFor, client } from '../../client';
import { useEffect } from 'react';
import { AppWrap, MotionWrap } from '../../wrapper';

const About = () => {

    const [abouts, setAbouts] = useState([]);
    useEffect(() => {

        const query = `*[_type == "abouts"]`;
        client.fetch(query).then((data) => setAbouts(data));

    }, []);

    return (

        <div className="about">
            <h2 className="about-text head-text">
                <p>About Me</p>  
            </h2>
            <div className="about-bio">
                <div className="profiles">
                    {
                        abouts.map((about, index) => (
                            <div className='profile-item' key={about.title + index}>
                                <motion.div
                                    whileInView={{ opacity: 1 }}
                                    whileHover={{ scale: 0.95 }}
                                    transition={{ duration: 0.3, type: 'tween' }}
                                >
                                    <img src={urlFor(about.imgUrl)} alt={about.title} />
                                </motion.div> 
                                <h2 className='bold-text' style={{marginTop: 20}}>{about.title}</h2>
                                <p className='p-text' style={{marginTop: 10}}>{about.description}</p>
                            </div>
                            ))
                    }
                </div>
                <div className="about-desc">
                    <p className="desc-text">
                        Hi! I am Jawad Taj from CSE department working on frontend development with backend integration. My two years of experience as a Front End Developer has shaped my JavaScript and React JS skills, as well as my knowledge of mobile and responsive design through CSS, SASS and Tailwind CSS.
                        My motive is to bring the wireframes of the UX designer to life through my set of skills, as well as collaborating with Back End Developers to create a stellar finished product. 
                    </p> 
                </div>
            </div>
        </div>

    )
}

export default AppWrap(MotionWrap(About, 'about'), 'about');