import React from 'react';
import { SocialMedia, NavigationDots } from '../components';

const AppWrap = (Component, idName, classNames) => function HOC() {

    return (

        <div id={idName} className={`app__container ${classNames}`}>
            <SocialMedia />
            <div className="app__wrapper app__flex">
                <Component />
                <div className="copyright">
                    <div className="p-text"> &copy; {new Date().getFullYear()} Jawad-Portfolio</div>
                    <div className="p-text">All Rights Reserved</div>
                </div>
            </div>
            <NavigationDots active={idName} />
        </div>
    )

}

export default AppWrap;