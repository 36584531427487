import React from 'react';
import './header.scss';
import { motion } from 'framer-motion';
import { images } from '../../constants';
import { AppWrap } from '../../wrapper';

const scaleVariants = {
    whileInView: {
        scale: [0, 1],
        opacity: [0, 1],
        transition: {
            duration: 0.5,
            ease: 'easeInOut',
        },
    },
};

const Header = () => {

    return (
        <div className='app-header app__flex'>
            <motion.div
                whileInView={{ x: [-100, 0], opacity: [0, 1] }}
                transition={{ duration: 0.8 }}
                className="header-info"
            >
                <div className="header-badge">
                    <div className="badge-cmp app__flex">
                        <div>
                            <h1 className="head-text">Hi! I am Jawad Taj</h1>
                        </div>
                    </div>
                    <div className="tag-cmp app__flex">
                        <p className="p-text">A Frontend Developer</p>
                    </div>
                </div>
            </motion.div>
            <motion.div
                whileInView={{ opacity: [0, 1] }}
                transition={{ duration: 0.5, delayChildren: 0.5 }}
                className="header-img"
            >
                <img src={images.profile} alt="profile_bg" />
                <motion.img
                    whileInView={{ scale: [0, 1] }}
                    transition={{ duration: 1, ease: 'easeInOut' }}
                    src={images.circle}
                    alt="profile_circle"
                    className="over-circle"
                />
            </motion.div>
            <motion.div
                variant={scaleVariants}
                whileInView={scaleVariants.whileInView}
                className="circle"
            >
                {
                    [images.react, images.next, images.sass].map((circle, index) => (
                        <div className="circle-cmp app__flex" key={`circle-${index}`}>
                            <img src={circle} alt="circle" />
                        </div>
                    ))
                }
            </motion.div>
        </div>
    )

}

export default AppWrap(Header, 'home');