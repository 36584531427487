import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { urlFor, client } from '../../client';
import { AppWrap, MotionWrap } from '../../wrapper';
import './skills.scss';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { MdWork } from 'react-icons/md';

const Skills = () => {

    const [experiences, setExperiences] = useState([]);
    const [skills, setSkills] = useState([]);

    useEffect(() => {

        const query = `*[_type == "experiences"]`;
        const skillsQuery = `*[_type == "skills"]`;

        client.fetch(query).then((data) => setExperiences(data));
        client.fetch(skillsQuery).then((data) => setSkills(data));

    }, []);

    return (
        <div className='skills'>
            <h2 className="head-text"><span>Skills</span> and Experiences</h2>
            <div className="skills-container">
                <motion.div className="skills-list">
                    {
                        skills?.map((skill) => (
                            <motion.div
                                whileInView={{ opacity: [0, 1] }}
                                transition={{ duration: 0.5 }}
                                className="skills-item app__flex"
                                key={skill.name}
                            >
                                <div className="app__flex" style={{ backgroundColor: skill.bgColor }}>
                                    <img src={urlFor(skill.icon)} alt={skill.name} />
                                </div>
                                <p className="p-text">{skill.name}</p>
                            </motion.div>
                        ))
                    }
                </motion.div>
                <motion.div className="skills-exp">
                    <VerticalTimeline animate={true}>
                        {experiences.map((item) => (
                            <VerticalTimelineElement
                                className="vertical-work"
                                contentStyle={{ background: '#fff', color: '#222'}}
                                contentArrowStyle={{ borderRight: '7px solid  #222'}}
                                iconStyle={{ background: '#fff', color: '#222'}}
                                icon={<MdWork />}
                                key={item.year}
                            >
                                {item?.works?.map((work) => (
                                    <div className='work-desc'  key={work.name}>
                                        <h3 className="bold-text">{work.name}</h3>
                                        <p className="p-text">{item.year}</p>
                                        <h4 className="head-text">{work.company}</h4>
                                        <p className="p-text">{work.desc}</p>
                                    </div>
                                ))}
                            </VerticalTimelineElement>
                        ))}
                    </VerticalTimeline>
                </motion.div>
            </div>
        </div>
    )

}

export default AppWrap(MotionWrap(Skills, 'skills'), 'skills');