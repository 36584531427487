import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

export const client = sanityClient({
    projectId: "xrhomt89",
    dataset: "production",
    apiVersion: "2022-12-09",
    useCdn: true,
    token: "skzeFBJjvQUfgTVuLiQV7nND8xYZCDaNTSlmxAiMyioGeor0KiHfASIRX8OctTEPG9LxQNJlqer2ywGdbLnD8j5p6a4p3vaHrDvEs0sWvAF4tWHs6YZMyf18QkEq8du4RER1xmG2X1m9hRrSSFbjl5Gqt4xrM8T8gKiAGeFraWpPL9S1uaEr",
});

const builder = imageUrlBuilder(client);
export const urlFor = (source) => builder.image(source);