import React, { useState } from 'react';
import { images } from '../../constants';
import { AppWrap, MotionWrap } from '../../wrapper';
import { client } from '../../client';
import './footer.scss';


const Footer = () => {

    const [formData, setFormData] = useState({ name: '', email: '', message: '' });
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    const { name, email, message } = formData;

    const handleChangeInput = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    }

    const handleSubmit = () => {

        setLoading(true);

        const contact = {
            _type: "contact",
            name: name,
            email: email,
            message: message,
        }

        client.create(contact)
        .then(() => {
            setLoading(false);
            setIsFormSubmitted(true);
        })
    }


    return (
        <div className='footer'>
            <h2 className="head-text">Take a coffee & chat with me</h2>
            <div className="footer-cards">
                <div className="footer-card">
                    <img src={images.email} alt="email" />
                    <a href="mailto: jawad.taj1998@gmail.com" className="p-text">jawad.taj1998@gmail.com</a>
                </div>
                <div className="footer-card">
                    <img src={images.whatsapp} alt="whatsapp" />
                    <a href="https://wa.me/+8801966599936" className="p-text">+88 01966599936</a>
                </div>
            </div>
            {
                !isFormSubmitted ? (
                    <div className="footer-form app__flex">
                        <div className="app__flex">
                            <input className="p-text" type="text" placeholder="Your Name" name="name" value={name} onChange={handleChangeInput} />
                        </div>
                        <div className="app__flex">
                            <input className="p-text" type="email" placeholder="Your Email" name="email" value={email} onChange={handleChangeInput} />
                        </div>
                        <div>
                            <textarea
                                className="p-text"
                                placeholder="Your Message"
                                value={message}
                                name="message"
                                onChange={handleChangeInput}
                            />
                        </div>
                        <button type="button" className="btn" onClick={handleSubmit}>
                            {!loading ? 'Send Message' : 'Sending...'}
                        </button>
                    </div>
                ) : 
                (
                    <div className="thanking">
                        <h3 className="head-text">
                            Thank you for getting in touch!
                        </h3>
                    </div>
                )
            }
        </div>
    );
    
};

export default AppWrap(MotionWrap(Footer, 'footer'), 'contact');