import email from "../images/email.png";
import mobile from "../images/mobile.png";
import api from "../images/api.png";
import cpp from "../images/cpp.png";
import css from "../images/css.png";
import figma from "../images/figma.png";
import flutter from "../images/flutter.png";
import git from "../images/git.png";
import graphql from "../images/graphql.png";
import html from "../images/html.png";
import javascript from "../images/javascript.png";
import mu5 from "../images/mu5.png";
import node from "../images/node.png";
import python from "../images/python.png";
import react from "../images/react.png";
import redux from "../images/redux.png";
import sass from "../images/sass.png";
import typescript from "../images/typescript.png";
import vue from "../images/vue.png";
import about01 from "../images/about01.png";
import about02 from "../images/about02.png";
import about03 from "../images/about03.png";
import about04 from "../images/about04.png";
import profile from "../images/profile.jpg";
import circle from "../images/circle.svg";
import logo from "../images/logo.png";
import adidas from "../images/adidas.png";
import amazon from "../images/amazon.png";
import asus from "../images/asus.png";
import bolt from "../images/bolt.png";
import nb from "../images/nb.png";
import skype from "../images/skype.png";
import spotify from "../images/spotify.png";
import node2 from "../images/node2.png";
import firebase from '../images/firebase.png';
import next from '../images/next.png';
import express from '../images/express.png';
import mongo from '../images/mongodb.png';
import sanity from '../images/sanity.png';
import whatsapp from '../images/whatsapp.png';


export default {
    email,
    mobile,
    api,
    cpp,
    css,
    figma,
    flutter,
    git,
    graphql,
    html,
    javascript,
    mu5,
    node,
    python,
    react,
    redux,
    sass,
    typescript,
    vue,
    about01,
    about02,
    about03,
    about04,
    profile,
    circle,
    logo,
    adidas,
    amazon,
    asus,
    bolt,
    nb,
    skype,
    spotify,
    node2,
    firebase,
    next,
    express,
    mongo,
    sanity,
    whatsapp,
};