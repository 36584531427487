import React from 'react';
import { BsLinkedin } from 'react-icons/bs';
import { FaFacebookF, FaGithub } from 'react-icons/fa';


const SocialMedia = () => {

    return (
        <div className='app__social'>
            <div>
                <a href="https://linkedin.com/in/taj-jawad-4635b023b" target="_blank" rel="noreferrer">
                    <BsLinkedin />
                </a>
            </div>
            <div>
                <a href="https://github.com/Jawad1998-ship-it" target="_blank" rel="noreferrer">
                    <FaGithub />
                </a>
            </div>
            <div>
                <a href="https://www.facebook.com/Jawad.Taj22" target="_blank" rel="noreferrer">
                    <FaFacebookF />
                </a>
            </div>
        </div>
    )

}

export default SocialMedia;